import React from 'react'
import * as styles from './styles.module.css'

interface Props {
	type: 'right'
}

export default function ChevronIcon({ type }: Props): JSX.Element {
	switch (type) {
		case 'right':
			return <div className={styles.right} />
		default:
			return <div className={styles.right} />
	}
}
