import React from 'react'
import * as styles from './styles.module.css'
import leftArrow from '@/assets/svg/left-arrow.svg'
import rightArrow from '@/assets/svg/right-arrow.svg'

type ButtonProps = {
	direction: string
	moveSlide: () => void
}

export default function BtnSlider({ direction, moveSlide }: ButtonProps) {
	return (
		<button
			onClick={moveSlide}
			className={direction === 'next' ? `${styles.btnSlide} ${styles.next}` : `${styles.btnSlide} ${styles.prev}`}
		>
			<img src={direction === 'next' ? rightArrow : leftArrow} />
		</button>
	)
}
