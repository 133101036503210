// extracted by mini-css-extract-plugin
export var active = "styles-module--active--174a3";
export var activeAnim = "styles-module--activeAnim--3f52c";
export var additionalTextArea = "styles-module--additionalTextArea--d5f89";
export var button = "styles-module--button--4c9b9";
export var buttonArea = "styles-module--buttonArea--398a1";
export var container = "styles-module--container--a3fa6";
export var containerDots = "styles-module--containerDots--75c04";
export var dot = "styles-module--dot--889bb";
export var image = "styles-module--image--344f2";
export var slide = "styles-module--slide--ee059";
export var textArea = "styles-module--textArea--71a09";