import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/organisms/Layout'
import Seo from '@/components/organisms/Seo'
import Testimonial from '@/components/organisms/Testimonial'
import ImageContent from '@/components/organisms/ImageContent'
import HeroSlides from '@/components/organisms/HeroSlides'

interface Props {
	data?: {
		content: {
			seo?: SeoMetagagsType
			section_testimonial?: TestimonialType
			section_hero?: HeroSlidesType
			section_image_content?: ImageContentType[]
		}
	}
}

const Homepage: React.FC<Props> = ({ data }) => {
	const { content } = data || {}
	return (
		<Layout>
			<div className="background home">
				<Seo {...content?.seo} />
				<HeroSlides {...content?.section_hero} hasButton={false} duration={8000} />

				{content?.section_image_content?.map((item) => (
					<ImageContent key={item.id} {...item} />
				))}
				<Testimonial {...content?.section_testimonial} />
			</div>
		</Layout>
	)
}

export const HomepageQuery = graphql`
	query {
		content: strapiPageHomepage {
			seo {
				description
				og_image {
					url
				}
				title
			}

			section_testimonial {
				author
				content
			}

			section_hero {
				actions {
					id
					title
					type
					url
				}
				bg_dark_level
				additional_text
				id
				slides {
					localFile {
						childImageSharp {
							gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, JPG])
						}
					}
				}
				content {
					data {
						content
					}
				}
			}

			section_image_content {
				heading
				content
				left_side_image
				id
				link {
					title
					type
					url
				}
				image {
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, JPG])
						}
					}
				}
			}

			section_testimonial {
				author
				content
			}
		}
	}
`

export default Homepage
