import React, { useEffect, useState } from 'react'
import Heading from '@/components/atoms/Heading'
import Button from '@/components/atoms/Button'
import Image from '@/components/atoms/Image'
import BtnSlider from '@/components/molecules/ButtonSlider'
import LinkComponent from '@/components/atoms/Link'
import MarkdownContent from '@/components/atoms/MarkdownContent'
import * as styles from './styles.module.css'

interface Props extends HeroSlidesType {
	hasButton?: boolean
	duration?: number
}
export default function HeroSlides({
	actions,
	additional_text,
	slides = [],
	content,
	hasButton,
	duration = 8000,
	bg_dark_level = 60,
}: Props) {
	/**
	 * @remarks
	 * This method is similar to the react-slider component, albeit lighter, if you need the buttons (optional), pass in the hasButton prop, this could come from the CMS as well.
	 * @param hasButton - This indicates if the button is enabled (true)
	 * @duration The duration of the slide in milliseconds
	 * */

	const [slideIndex, setSlideIndex] = useState(1)
	const slidesLength = slides?.length || 0

	const nextSlide = () => {
		setSlideIndex((current) => (current === slides.length ? 1 : current + 1))
	}

	useEffect(() => {
		if (hasButton) return
		const interval = setInterval(nextSlide, duration)

		// eslint-disable-next-line consistent-return
		return () => {
			clearInterval(interval)
		}
	}, [])

	const nextSlideBtn = () => {
		if (slideIndex !== slides.length) {
			setSlideIndex(slideIndex + 1)
		} else if (slideIndex === slidesLength) {
			setSlideIndex(1)
		}
	}

	const prevSlideBtn = () => {
		if (slideIndex !== 1) {
			setSlideIndex(slideIndex - 1)
		} else if (slideIndex === 1) {
			setSlideIndex(slidesLength)
		}
	}

	const goToSlide = (index: number) => {
		setSlideIndex(index)
	}

	return (
		<section>
			<div className={styles.container}>
				{slides
					?.filter((slide) => !!slide.localFile)
					.map((slide, index) => {
						return (
							<div
								key={slide.localFile.id}
								className={
									slideIndex === index + 1 ? ` ${styles.slide} ${styles.activeAnim}` : ` ${styles.slide}`
								}
							>
								<Image
									image={slide}
									className={styles.image}
									style={{ filter: `brightness(${bg_dark_level}%)` }}
								/>
							</div>
						)
					})}

				<div className={styles.textArea}>
					{content && <MarkdownContent content={content} />}
					<div className={styles.buttonArea}>
						{actions?.map((action) => (
							<LinkComponent key={action.id} url={action.url} type={action.type} className={styles.button}>
								<Button type="button">{action.title}</Button>
							</LinkComponent>
						))}
					</div>
				</div>
				{hasButton && (
					<>
						<BtnSlider moveSlide={nextSlideBtn} direction="next" />
						<BtnSlider moveSlide={prevSlideBtn} direction="prev" />
					</>
				)}

				<div className={styles.containerDots}>
					{slides?.map((item, index) => {
						return (
							// eslint-disable-next-line jsx-a11y/control-has-associated-label
							<button
								key={`${item.name}_${index}`}
								onClick={() => goToSlide(index + 1)}
								className={slideIndex === index + 1 ? `${styles.dot} ${styles.active}` : `${styles.dot}`}
							/>
						)
					})}
				</div>
			</div>
			{additional_text && (
				<div className={styles.additionalTextArea}>
					<p>{additional_text}</p>
				</div>
			)}
		</section>
	)
}
