import React from 'react'
import clsx from 'clsx'

import Image from '@/components/atoms/Image'
import Text from '@/components/atoms/Text'

import * as styles from './styles.module.css'

const Testimonial: React.FC<TestimonialType> = ({ content, author, background_image }) => {
	const isBackgound = !!background_image
	return (
		<section
			className={clsx(styles.wrapper, {
				[styles.withBg]: isBackgound,
				[styles.withoutBg]: !isBackgound,
			})}
		>
			<div>
				{isBackgound && <Image image={background_image} className={styles.imageStyle} />}
				<div className={clsx('container', styles.textArea)}>
					<Text level={2}>{content}</Text>
					<span>{author}</span>
				</div>
			</div>
		</section>
	)
}

export default Testimonial
