import React from 'react'
import clsx from 'clsx'
import Heading from '@/components/atoms/Heading'
import Link from '@/components/atoms/Link'
import Image from '@/components/atoms/Image'
import ChevronIcon from '@/components/atoms/ChevronIcon'
import Text from '@/components/atoms/Text'

import * as styles from './styles.module.css'

export default function ImageContent({ image, left_side_image, link, content, heading }: ImageContentType) {
	return (
		<section className={styles.wrapper}>
			<div className="container">
				<div className={clsx(styles.inner, { [styles.inner_left]: !!left_side_image })}>
					<div className={clsx(styles.imageWrapper, { [styles.imageWrapper_left]: !!left_side_image })}>
						<Image image={image} alt={image?.alternativeText || ''} />
						<div className={clsx(styles.triangle, { [styles.triangle_right]: !left_side_image })} />
					</div>

					<div className={styles.contentWrapper}>
						<Heading level={2} variant="bold">
							{heading}
						</Heading>
						<Text level={1}>{content}</Text>
						{link && (
							<Link className={styles.link} url={link.url} type={link.type}>
								{link.title}
								<ChevronIcon type="right" />
							</Link>
						)}
					</div>
				</div>
			</div>
		</section>
	)
}
